import React from "react";
import {websites} from '../constants/data'

class Portfolio extends React.Component {
  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Portfolio</h3>
                <h2 className="w-title">Hi! here you can check some of my websites, I hope you like it.</h2>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {websites&&websites.map((web,key)=>{
              return (
                      <article className="col-md-4" key={key}>
                      <div className="work-box">
                        <a href={web.portada} data-lightbox={web.lightBox}>
                          <div className="work-img">
                            <img src={web.portada} alt={web.title} className="img-fluid" />
                          </div>
                          <div className="work-content">
                            <div className="row">
                              <div className="col-sm-8">
                                <h2 className="w-title">{web.title}</h2>
                                <h2 className="small">{web.subtitle}</h2>
                                <h2 className="small">{web.author}</h2>
                                <div className="w-more">
                                  <label className="w-ctegory">{web.tech}</label>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="w-like">
                                  <label className="ion-ios-plus-outline"></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                        {web.image.map((img,key)=>{
                          return(<a href={img} data-lightbox={web.lightBox} style={{ display: "none" }} key={key}>Caracteristicas</a>)
                        })}
                        <div>
                          <button style={{margin:"5px", borderRadius:"25px", borderColor:"#0078ff"}}>
                            <a href={web.link} target="_blank" rel="noreferrer">LINK</a>
                          </button>
                          {web.download?
                          <button style={{margin:"5px", borderRadius:"25px", borderColor:"#0078ff"}}>
                            <a href={web.download} target="_blank" rel="noreferrer"><i className="ion-android-download"/></a>
                          </button>:null}
                        </div>
                      </div> 
                    </article>
                  )
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
