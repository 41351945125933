import React from "react";
import "./stars.scss";
import bigImage from '../img/PortfolioBk.webp';
import Typed from "react-typed";

let name = "Gonzalo";
let lastname = "Torres Grau";

class Intro extends React.Component {
  
  render() {
    return (
      <section>
      <div id="home" className="intro route bg-image" style={{backgroundImage: "url("+bigImage+")"}}>
       {/* <div id="home" className="intro route bg-image background">
         <div id="stars" />
         <div id="stars2" />
         <div id="stars3" /> */}

        <div className="intro-content display-table">
          <div className="table-cell" style={{backdropFilter: "blur(10px)"}}>
            <div className="container">
              <h1 className="intro-title mb-4">Hi, I am</h1>
              <h1 className="intro-title mb-4">{name} {lastname}</h1>
              <p className="intro-subtitle">
                <span className="text-slider-items"></span>
                <strong className="text-slider">
                  <Typed
                    strings={[
                      "Front End Developer",
                      "Back End Developer",
                      "Software Engineer",
                      "Electronic Technician",
                    ]}
                    typeSpeed={80}
                    backDelay={1100}
                    backSpeed={30}
                    loop
                  />
                </strong>
              </p>
              <p className="pt-3">
                <a className="btn btn-primary btn js-scroll px-4" href="#work" role="button">
                  View My Work
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      </section>
    );
  }
}

export default Intro;
