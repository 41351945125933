import React from "react";
import EF from '../img/ef.webp';
import ET2 from '../img/et2.webp';
import Henry from '../img/Henry.webp';
import UPSH from '../img/UpskillingH.webp'
import UBA from '../img/UBA.webp';



let about1 = "Hi! Welcome to my website! Im Gonzalo, an Electronics Technician and Full Stack web developer with a B2 - Upper Intermediate level of English and Intermediate level of Portuguese. I have experience with various languages, such as: "

let tecnologies= 'HTML5, CSS3, Tailwindcss,JavaScript,TypeScript, React, Redux, Node.js, Express, Next.js, MySQL, and PostgreSQL.'

let about2 = "I stand out for my ability to work in teams, organize tasks, think creatively, and solve problems autonomously, all supported by effective communication."

let about3 = "Throughout my career, I have worked on projects that include features like API consumption, user management, an exclusive admin dashboard, stock management, favorites functionality, payment gateway integration (MercadoPago), image carousels, third-party authentication, and the implementation of the SCRUM methodology. I hope you enjoy exploring my work!"

class About extends React.Component {
  
  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="about-me pt-4 pt-md-0">
                        <h2 className="title-left p-2">About Me</h2>
                      </div>
                      <br/>
                      <p className="lead p-2 w-title" >{about1}</p>
                      <p className="lead p-2 w-title" style={{ fontWeight: "bold" }}>{tecnologies}</p>
                      <p className="lead p-2 w-title" >{about2}</p>
                      <p className="lead p-2 w-title" >{about3}</p>
                    </div>
                    <br/>
                    <div className="about-me pt-4 pt-md-0">
                        <h6 className="title-left p-2">Certifications</h6>
                        <div className="socials">
                              <br/>
                              <ul>
                                <li><a href="https://www.efset.org/cert/qM3RBv" target="_blank" rel="noopener noreferrer"><label><img src={EF} alt="Certificate English" style={{cursor: "pointer"}} /></label></a>
                                </li>
                                <li><a href="https://certificates.soyhenry.com/cert?id=749046a9-0e89-4d0f-90ae-7574c3757708" target="_blank" rel="noopener noreferrer"><label><img src={Henry} alt="Certificate Henry" style={{cursor: "pointer"}}/></label></a>
                                </li>
                                <li><a href="https://certificates.soyhenry.com/new-cert?id=7c0a8221da10e20ca9eaafa0c04325f1c9205e1e2a13c1607c644f71ff9bb0f3" target="_blank" rel="noopener noreferrer"><label><img src={UPSH} alt="Certificate Henry" style={{cursor: "pointer"}} width='auto' height={75}/></label></a>
                                </li>
                                <li><a href="https://drive.google.com/file/d/1akP5I1ikdJtCXSctHvYRGAZcD7FryVZi/view" target="_blank" rel="noopener noreferrer"><label><img src={UBA} alt="Certificate Portugues" style={{cursor: "pointer"}}/></label></a>
                                </li>
                                <li><a href="https://drive.google.com/file/d/1I2_OL4qSw_li_aDsF5xw7LJ55sqy6RMH/view" target="_blank" rel="noopener noreferrer"><label><img src={ET2} alt="Certificate Electronic Technician" style={{cursor: "pointer"}}/></label></a>
                                </li>
                              </ul>
                        </div>
                    </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
