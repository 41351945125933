
import CCW1 from "../img/CCW/Busqueda.webp";
import CCW2 from "../img/CCW/Contacto.webp";
import CCW3 from "../img/CCW/Detalle.webp";
import CCW4 from "../img/CCW/Home.webp";
import CCW5 from "../img/CCW/Newsletter.webp";
import CCW6 from "../img/CCW/Opiniones.webp";
import CCW7 from "../img/CCW/Responsive1.webp";
import CCW8 from "../img/CCW/Responsive2.webp";

import Alarma1 from "../img/AlarmaGeografica/Home.png";
import Alarma2 from "../img/AlarmaGeografica/Caracteristicas.png";
import Alarma3 from "../img/AlarmaGeografica/ComoSoy.png";
import Alarma4 from "../img/AlarmaGeografica/Descargas.png";
import Alarma5 from "../img/AlarmaGeografica/Paraque.png";
import Alarma6 from "../img/AlarmaGeografica/Contacto.png";
import Alarma7 from "../img/AlarmaGeografica/Responsive1.png";
import Alarma8 from "../img/AlarmaGeografica/Responsive2.png";

import residencia1 from "../img/ResidenciaSalomon/1.png";
import residencia2 from "../img/ResidenciaSalomon/2.png";
import residencia3 from "../img/ResidenciaSalomon/3.png";
import residencia4 from "../img/ResidenciaSalomon/4.png";
import residencia5 from "../img/ResidenciaSalomon/5.png";
import residencia6 from "../img/ResidenciaSalomon/6.png";

import Sneakers1 from "../img/Sneakers/Home.png";
import Sneakers2 from "../img/Sneakers/Login.png";
import Sneakers3 from "../img/Sneakers/Shop.png";
import Sneakers4 from "../img/Sneakers/Filtered.png";
import Sneakers5 from "../img/Sneakers/Detail.png";
import Sneakers6 from "../img/Sneakers/Favorites.png";
import Sneakers7 from "../img/Sneakers/Cart.png";
import Sneakers8 from "../img/Sneakers/Profile.png";
import Sneakers9 from "../img/Sneakers/PurchaseHistoy.png";
import Sneakers10 from "../img/Sneakers/AdminDashboard.png";
import Sneakers11 from "../img/Sneakers/NewProduct.png";

import Countries1 from "../img/Countries/Home.png";
import Countries2 from "../img/Countries/CountryDetail.png";
import Countries3 from "../img/Countries/FilterByActivities.png";
import Countries4 from "../img/Countries/FilteredByIna.png";
import Countries5 from "../img/Countries/Landing.png";
import Countries6 from "../img/Countries/NewActivity.png";
import Countries7 from "../img/Countries/Responsive.png";

import Programundo1 from "../img/Programundo/home.png";
import Programundo2 from "../img/Programundo/about.png";
import Programundo3 from "../img/Programundo/Proyectos.png";
import Programundo4 from "../img/Programundo/Contacto.png";
import Programundo5 from "../img/Programundo/responsive1.png";
import Programundo6 from "../img/Programundo/responsive2.png";
import Programundo7 from "../img/Programundo/responsive3.png";

import Sermar1 from '../img/Sermar/home.webp'
import Sermar2 from '../img/Sermar/nosotros.webp'
import Sermar3 from '../img/Sermar/servicios.webp'
import Sermar4 from '../img/Sermar/contacto.webp'
import Sermar5 from '../img/Sermar/galeria1.webp'
import Sermar6 from '../img/Sermar/responsive.webp'
import Sermar7 from '../img/Sermar/responsive2.webp'
import Sermar8 from '../img/Sermar/responsive3.webp'
import Sermar9 from '../img/Sermar/responsive4.webp'


import Romihsale1 from "../img/romihsale/1.webp";
import Romihsale2 from "../img/romihsale/2.webp";
import Romihsale3 from "../img/romihsale/3.webp";
import Romihsale4 from "../img/romihsale/4.webp";
import Romihsale5 from "../img/romihsale/5.webp";
import Romihsale6 from "../img/romihsale/6.webp";
import Romihsale7 from "../img/romihsale/7.webp";
import Romihsale8 from "../img/romihsale/8.webp";


export const websites = [
       {
        title:"Centro Comercial Wilde",
        lightBox:'CCW',
        subtitle:"Landing Website",
        author:'PROGRAMUNDO',
        tech:"TYPESCRIP TAILWINDCSS REACT NEXT",
        portada:CCW1,
        link:"https://centrocomercialwilde.com/",
        download:"",
        image:[CCW2,CCW3,CCW4,CCW5,CCW6,CCW7,CCW8]
    },
    {
        title:"Alarma Geográfica APP",
        lightBox:'AGA',
        subtitle:"Product WebSite",
        author:'OWN',
        tech:"HTML5 CSS3 JAVASCRIPT",
        portada:Alarma1,
        link:"https://alarmageografica.com/",
        download:"https://play.google.com/store/apps/details?id=com.alarmageografica.app",
        image:[Alarma2,Alarma3,Alarma4,Alarma5,Alarma6,Alarma7,Alarma8]
    },
    {
        title:"Residencia Salomon",
        lightBox:'RSalomon',
        subtitle:"Landing Website",
        author:'PROGRAMUNDO',
        tech:"JAVASCRIPT REACT NEXT CHAKRA",
        portada:residencia1,
        link:"https://residenciasalomon.com/",
        download:"",
        image:[residencia2,residencia3,residencia4,residencia5,residencia6]
    },
    {
        title:"Programundo",
        lightBox:'Programundo',
        subtitle:"Landing WebSite",
        author:'PROGRAMUNDO',
        tech:"REACT JAVASCRIPT TAILWIND CSS",
        portada:Programundo1,
        link:"https://programundo.dev/",
        download:"",
        image:[Programundo2,Programundo3,Programundo4,Programundo5,Programundo6,Programundo7]
    },
    {
        title:"ROMIHSALE",
        lightBox:'ROMIHSALE',
        subtitle:"Landing WebSite",
        author:'OWN',
        tech:"REACT JAVASCRIPT TAILWIND CSS",
        portada: Romihsale1,
        link:"https://romihsale.com/",
        download:"",
        image:[Romihsale2,Romihsale3,Romihsale4,Romihsale5,Romihsale6,Romihsale7,Romihsale8]
    },
    {
        title:"Shop Sneakers",
        lightBox:'SSneaker',
        subtitle:"Ecommerce WebSite",
        author:'PROGRAMUNDO',
        tech:"REACT REDUX POSTGRESQL MATERIAL-UI",
        portada:Sneakers1,
        link:"https://pf-07-e-commerce-front.vercel.app/sneakers",
        download:"",
        image:[Sneakers2,Sneakers3,Sneakers3,Sneakers4,Sneakers5,Sneakers5,Sneakers6,Sneakers7,Sneakers8,Sneakers9,Sneakers10,Sneakers11]
    }, 
    {
        title:"Sermar Aire",
        lightBox:'sermar',
        subtitle:"Landing WebSite",
        author:'OWN',
        tech:"REACT TypeScript tailwindCSS Next",
        portada:Sermar1,
        link:"https://sermaraire.com",
        download:"",
        image:[Sermar2,Sermar3,Sermar4,Sermar5,Sermar6,Sermar7,Sermar8,Sermar9]
    },  
    {
        title:"Countries",
        lightBox:'Countries',
        subtitle:"Informative WebSite",
        author:'OWN',
        tech:"CSS REACT REDUX POSTGRESQL",
        portada:Countries1,
        link:"https://gtg-henry-pi-client.vercel.app/",
        download:"",
        image:[Countries2,Countries3,Countries4,Countries5,Countries6,Countries7]
    },
]